import React from 'react';
import classNames from 'classnames';
import { SmallStars } from '@wix/ftsystem';
import { classes } from './ready-ratings.st.css';
import { useTranslate } from '../../../use-translate';
import { RATING, RATING_TEXT } from '~ratings/constants/data-hooks';
import { debounce } from 'lodash';
import { useApi } from '~ratings/Widget/components/api-provider/use-api';
import { useSlotContext } from '@wix/widget-plugins-ooi-context';

export const ReadyRatings = ({
  overall,
  totalReviews,
  onClick,
}: {
  overall: number;
  totalReviews: number;
  onClick?: () => void;
}) => {
  const {
    collapsible,
    isCollapsed,
    registerStarsWidth,
    registerContainer,
    unregisterContainer,
    starsWidth,
  } = useApi((ctx) =>
    ctx.type === 'multi_state'
      ? {
          collapsible: true,
          starsWidth: ctx.state.layout.starsWidth,
          isCollapsed: ctx.state.layout.isCollapsed,
          registerStarsWidth: ctx.actions.registerStarsWidth,
          registerContainer: ctx.actions.registerContainer,
          unregisterContainer: ctx.actions.unregisterContainer,
        }
      : { collapsible: false },
  );
  const [containerRef, setContainerRef] = React.useState<HTMLElement | null>(null);
  const [ratingInfoRef, setRatingInfoRef] = React.useState<HTMLElement | null>(null);
  const [starsRef, setStarsRef] = React.useState<HTMLElement | null>(null);
  const slotContext = useSlotContext();
  React.useEffect(() => {
    if (!collapsible || starsRef === null || containerRef === null || ratingInfoRef === null) {
      return;
    }
    // set initial width, so it always fills the slot
    containerRef.style.width = window.getComputedStyle(containerRef).width;
    if (starsWidth === 0) {
      registerStarsWidth?.(starsRef.scrollWidth);
      return;
    }
    const updateContainerWidth = () => {
      const gap = parseInt(window.getComputedStyle(containerRef).gap, 10);
      if (slotContext.type === 'initialized') {
        registerContainer?.({
          width: containerRef.clientWidth - ratingInfoRef.clientWidth - gap,
          resourceId: slotContext.value.resourceId as string,
        });
      }
    };

    updateContainerWidth();
    const observer = new ResizeObserver(debounce(updateContainerWidth, 500));
    observer.observe(containerRef);
    return () => {
      if (slotContext.type === 'initialized') {
        unregisterContainer?.({
          resourceId: slotContext.value.resourceId as string,
        });
      }
      observer.unobserve(containerRef);
    };
  }, [collapsible, starsRef, containerRef, ratingInfoRef, starsWidth]);
  const t = useTranslate();

  const children = (
    <>
      <div ref={setStarsRef}>
        <SmallStars
          className={classNames(
            classes.stars,
            collapsible && starsWidth === undefined ? classes.invisible : '',
          )}
          rating={overall}
          collapsed={isCollapsed}
        />
      </div>
      <div
        data-hook={RATING_TEXT}
        className={classNames(classes.ratingInfo, collapsible && classes.compact)}
        ref={setRatingInfoRef}
      >
        {t(collapsible ? 'ratings-widget.compact-label' : 'ratings-widget.full-label', {
          rating: overall.toFixed(1),
          count: totalReviews,
        })}
      </div>
    </>
  );
  return onClick ? (
    <button
      ref={setContainerRef}
      className={classes.root}
      data-hook={RATING}
      onClick={() => {
        onClick();
      }}
    >
      {children}
    </button>
  ) : (
    <div className={classes.root} data-hook={RATING} ref={setContainerRef}>
      {children}
    </div>
  );
};
